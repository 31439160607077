/* eslint-disable max-len */
import { FC } from 'react';

interface Props {
    logoColor?: string;
}

const ActiveCaptainLogo: FC<Props> = (props) => {
    const { logoColor } = props;

    const fillStyle = { fill: logoColor || '#FFFFFF' };

    return (
        <svg x="0px" y="0px" height="100%" viewBox="0 0 138 36" data-testid="logo">
            <g style={{ transform: 'scale(0.21)' }}>
                <g>
                    <g>
                        <path
                            style={fillStyle}
                            d="M573.1,138.1c-3.4,0-5.9-2.6-5.9-5.9c0-3.3,2.5-5.9,5.9-5.9c3.4,0,5.9,2.6,5.9,5.9C579,135.5,576.5,138.1,573.1,138.1z
                            M573.1,127.1c-3,0-5,2.3-5,5.1c0,2.8,2.1,5.1,5,5.1c3,0,5-2.3,5-5.1C578.1,129.3,576.1,127.1,573.1,127.1z M575.4,135.2
                            c-0.8,0-1.1-0.4-1.1-1v-0.6c0-0.5-0.3-0.9-0.8-0.9h-1.3v2.4H571v-5.8h2.6c1.3,0,1.8,0.7,1.8,1.6v0c0,0.7-0.2,1.1-0.9,1.3
                            c0.7,0.1,0.9,0.7,0.9,1.3v0.6c0,0.2,0.1,0.3,0.4,0.3v0.8H575.4z M574.3,130.9c0-0.3-0.3-0.8-0.8-0.8h-1.4v1.7h1.3
                            c0.5,0,0.8-0.3,0.8-0.8V130.9z"
                        />
                    </g>
                    <g>
                        <path
                            style={fillStyle}
                            d="M112.3,171.9l-2.9-11.7H97.7l-2.8,11.7h-7.1l12-45.5h7.6l12.1,45.5H112.3z M103.4,136.2l-4.3,17.9h8.7L103.4,136.2z"
                        />
                        <path
                            style={fillStyle}
                            d="M144.9,173.1c-10.2,0-14.7-6.8-14.7-14.2v-19.4c0-7.4,4.5-14.2,14.7-14.2c10.2,0,14.4,6,14.4,13.3v3.7h-6.5v-3.2
                            c0-4.6-2.5-7.5-7.6-7.5s-7.6,3-7.6,7.2v20.8c0,4.1,2.5,7.1,7.6,7.1s7.6-2.9,7.6-7.5v-3.2h6.5v3.7
                            C159.3,167,155.1,173.1,144.9,173.1z"
                        />
                        <path style={fillStyle} d="M187.7,133v38.9h-7.2V133h-11.1v-6.5h29.4v6.5H187.7z" />
                        <path style={fillStyle} d="M210.7,171.9v-45.5h7.2v45.5H210.7z" />
                        <path
                            style={fillStyle}
                            d="M249.4,171.9h-6.8l-12.8-45.5h7.6l8.8,35.4l8.7-35.4h7.5L249.4,171.9z"
                        />
                        <path
                            style={fillStyle}
                            d="M274.1,171.9v-45.5h24.6v6.5h-17.3v12h12.5v6.1h-12.5v14.4h17.3v6.5L274.1,171.9L274.1,171.9z"
                        />
                        <path
                            style={fillStyle}
                            d="M326,173.1c-10.2,0-14.7-6.8-14.7-14.2v-19.4c0-7.4,4.5-14.2,14.7-14.2c10.2,0,14.4,6,14.4,13.3v3.7h-6.5v-3.2
                            c0-4.6-2.5-7.5-7.6-7.5s-7.6,3-7.6,7.2v20.8c0,4.1,2.5,7.1,7.6,7.1s7.6-2.9,7.6-7.5v-3.2h6.5v3.7C340.4,167,336.2,173.1,326,173.1
                            z"
                        />
                        <path
                            style={fillStyle}
                            d="M374.6,171.9l-2.9-11.7H360l-2.8,11.7h-7.1l12-45.5h7.6l12.1,45.5H374.6z M365.7,136.2l-4.3,17.9h8.7L365.7,136.2z"
                        />
                        <path
                            style={fillStyle}
                            d="M408.8,154.1h-8v17.8h-7.2v-45.5h15.3c9,0,12.2,4.9,12.2,11.6v4.6C421,149.3,417.7,154.1,408.8,154.1z M413.7,137.9
                            c0-3.3-1.2-5.4-4.9-5.4h-8.1v15.7h8.1c3.7,0,4.9-2.1,4.9-5.5V137.9z"
                        />
                        <path style={fillStyle} d="M449.2,133v38.9h-7.2V133h-11.1v-6.5h29.4v6.5H449.2z" />
                        <path
                            style={fillStyle}
                            d="M490.9,171.9l-2.9-11.7h-11.7l-2.8,11.7h-7.1l12-45.5h7.6l12.1,45.5H490.9z M482,136.2l-4.3,17.9h8.7L482,136.2z"
                        />
                        <path style={fillStyle} d="M509.8,171.9v-45.5h7.2v45.5H509.8z" />
                        <path
                            style={fillStyle}
                            d="M556.8,171.9l-13.1-21.7l-4.6-8.3v30.1h-6.8v-45.5h5.6l12.7,22.1l4.4,8.3v-30.5h6.8v45.5H556.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                style={fillStyle}
                                d="M644.7,73.3c5.7,0,10.3,4.6,10.3,10.3s-4.6,10.3-10.3,10.3s-10.3-4.6-10.3-10.3C634.4,77.9,639,73.3,644.7,73.3z
                                M644.7,92.4c4.7,0,8.3-3.8,8.3-8.8c0-4.9-3.6-8.8-8.3-8.8c-4.8,0-8.4,3.8-8.4,8.8C636.4,88.6,639.9,92.4,644.7,92.4z
                                M640.7,77.6h4.6c2.8,0,4.1,1.1,4.1,3.4c0,1.9-1.2,3.3-3.1,3.3l3.4,5.3h-2.1l-3.4-5.3h-1.6v5.3h-2L640.7,77.6L640.7,77.6z
                                M642.7,82.8h2.3c1.5,0,2.5-0.3,2.5-1.9c0-1.4-1.2-1.8-2.5-1.8h-2.3V82.8z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={fillStyle}
                                d="M170.9,5.8c-1.1-2.2-4-4-6.4-4h-5c-2.5,0-5.3,1.8-6.4,4l-39.5,82.6c-1.1,2.2,0.1,3.8,2.5,3.8h9.1c3.6,0,5.2-1.6,6.2-3.6
                                c1-2,3.2-6.4,3.6-7.3c0.6-1.2,2.3-1.9,4.8-1.9l42.5,0c2.5,0,4,0.5,4.8,1.9c0.5,0.9,2.6,4.9,3.7,7c1.1,2.1,2.9,3.9,6.4,3.9h13.3
                                c2.5,0,3.5-1.9,2.6-3.6C212.2,86.8,170.9,5.8,170.9,5.8z M146,66.5c-2.5,0-3.6-1.8-2.6-4L159,29.1c1-2.2,2.7-2.2,3.7,0l15.2,33.4
                                c1,2.2-0.2,4-2.6,4L146,66.5z M476.3,6.4c0,0.8,0,80.3,0,81.9c0,2.3,2,3.8,4.5,3.8h10.8c2.5,0,4.4-1.6,4.4-4V6.2
                                c0-2.5-1.6-4.4-4-4.4h-11.2C478.3,1.8,476.3,3.3,476.3,6.4z M295.3,57.7c-1.4-2-0.6-4.2,1.7-4.9c0,0,9.3-2.8,13.8-6.8
                                c4.5-4,6.7-9.8,6.7-17.4c0-4.9-0.9-9.1-2.6-12.5s-4.2-6.1-7.4-8.2c-3.2-2.1-7-3.7-11.5-4.7c-4.5-1-10.5-1.2-10.5-1.2
                                c-2.5-0.1-6.5-0.2-8.9-0.2h-44.8c-2.5,0-4.4,2-4.4,4.4v81.9c0,2.5,2,4,4.4,4h10.6c2.5,0,4.5-1.6,4.5-4c0,0,0-32.6,0-32.6
                                c0,0,0,0,0,0c0,0,20.1-0.1,20.1-0.1c2.5,0,5.6,1.6,7,3.6l20.9,29.4c1.9,2.6,3.8,3.6,6.2,3.6H315c2.5,0,2.8-2.3,2-3.5
                                C316.2,87.4,295.3,57.7,295.3,57.7z M286.9,41.1c-2.7,0.6-5.8,0.8-5.8,0.8c-2.5,0.1-6.5,0.2-8.9,0.2h-20.8c-2.5,0-4.5-2-4.5-4.5
                                V21c0-2.5,2-4.5,4.5-4.5h20.8c2.5,0,6.5,0.1,8.9,0.2c0,0,3.1,0.1,5.8,0.8c2.8,0.6,4.9,1.5,6.5,2.7c1.6,1.1,2.7,2.5,3.4,4
                                s1,3.2,1,5.1c0,1.8-0.3,3.5-1,5.1c-0.7,1.6-1.8,2.9-3.4,4C291.8,39.6,289.6,40.5,286.9,41.1z M608.8,1.8c-2.5,0-4.4,1.6-4.4,4
                                c0,1.1,0,52.4,0,52.4c0,2.5-1.4,3-3.1,1.2L548.9,5c-1.7-1.8-4.2-3.2-7.5-3.2h-9.9c-3.1,0-4.5,1.6-4.5,3.3c0,1.7,0,82.1,0,83.7
                                c0,1.7,1.6,3.3,4,3.3h9.9c2.5,0,4.2-1.3,4.2-3.6c0-1.1,0.1-55.2,0.1-55.2c0-2.5,1.4-3,3.1-1.2l54.7,56.8c1.7,1.7,3.8,3.2,7.6,3.2
                                h7.4c2.5,0,4.5-2,4.5-4.4V6.2c0-2.5-2-4.4-4.5-4.4L608.8,1.8L608.8,1.8z M395.1,52.8c-1.2,2.1-3.1,2.1-4.3,0L365.1,5.8
                                c-1.1-2.2-3.3-4-6.4-4h-13.9c-2.5,0-4.5,2-4.5,4.4V88c0,2.5,1.6,4.1,4.5,4.1h8.6c2.5,0,4.2-1.6,4.2-3.9c0-1.1,0-59.5,0.1-59.5
                                s32,59.5,32,59.5c1.1,2.2,4,2.2,5.2,0c0,0,32.2-59.3,32.3-59.3s0.1,57.6,0.1,59.3c0,2.4,1.9,3.9,4.3,3.9h9.9
                                c2.5,0,4.4-1.2,4.4-4.4V6.2c0-2.5-2-4.4-4.4-4.4h-13.3c-3.4,0-5.4,1.7-6.7,3.9L395.1,52.8z"
                            />
                        </g>
                        <g>
                            <path
                                style={fillStyle}
                                d="M0,46.9C0,91,45.1,93.2,54.1,93.2c29.9,0,42.6-8.1,43.1-8.4c1.9-1.1,4.2-3.2,4.2-7.2V47.1c0-2.8-2.3-5.1-5.1-5.1H58.7
                                c-2.8,0-4.7,2.3-4.7,5.1v3.2c0,2.8,1.8,5.1,4.7,5.1h21.8c2.1,0,3.8,1.7,3.8,3.8c0,0,0,13.4,0,14.2c-4.3,1.8-23.9,7.5-39.6,3.7
                                c-22.8-5.5-24.6-24.3-24.6-29.9c0-4.7,1.4-28.4,29.4-30.8c22.7-1.9,37.6,7.2,37.8,7.3c2.7,1.4,5.4,0.4,6.9-2.4
                                c0,0,2.8-5.7,2.8-5.7c1.2-2.4,0.8-4.8-2.1-6.7C94.8,8.6,78.9,0,54.2,0C1.5,0,0,42.1,0,46.9z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ActiveCaptainLogo;
